@import '_reset.scss';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

body, html {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  background: #f5f8ff;
}

.container {
  max-width: 1240px;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
}
header {
  position: sticky;
  top: 10px;
  height: 80px;
  z-index: 10;
  margin-bottom: -80px;
  .head-box {
    height: 80px;
    background: rgba(0,0,0,.8);
/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#320e71+0,1c2584+100&0.8+0,0.8+100 */
background: -moz-linear-gradient(top,  rgba(50,14,113,0.8) 0%, rgba(28,37,132,0.8) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(50,14,113,0.8) 0%,rgba(28,37,132,0.8) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(50,14,113,0.8) 0%,rgba(28,37,132,0.8) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cc320e71', endColorstr='#cc1c2584',GradientType=0 ); /* IE6-9 */
    color: white;
    backdrop-filter: blur(2px);
    border-radius: 8px;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    padding: 0 24px;
  }
  .logo a {
    display: block;
    width: 80px;
    height: 80px;
    background: url("../img/logo.svg") no-repeat 50% 50% / contain;
    text-indent: -10000px;
    overflow: hidden;
    animation: .5s ease-out .5s 1 normal backwards running mainnav;
    @keyframes mainnav {
      from { transform: translateY(10px); opacity: 0; }
      to   { transform: translateY(0deg); opacity: 1; }
    }
  }
  nav {
    margin-left: auto;
    display: flex;
    align-items: center;
    >ul {
      display: flex;
      >li {
        margin-left: 20px;
        position: relative;
        a {
          display: block;
          padding: 2px 5px;
          font-size: 18px;
          font-weight: 300;
          text-transform: uppercase;
        }
        &::before, &::after {
          height: 1px;
          position: absolute;
          width: 0;
          content: "";
          bottom: 0;
          background: white;
          transition: .3s;
        }
        &::before {
          right: 49%;
          transform-origin: 100% 0%;
          transform: skewY(-1deg);
        }
        &::after {
          left: 49%;
          transform: skewY(-1deg);
          transform-origin: 0% 0%;
        }
        &:hover {
          &::before, &::after {
            width: 50%;
          }
        }
      }
    }
    animation: .5s ease-out .5s 1 normal backwards running mainnav;
    @keyframes mainnav {
      from { transform: translateY(10px); opacity: 0; }
      to   { transform: translateY(0deg); opacity: 1; }
    }
  }
  .adv-btn {
    display: flex;
/*     border: 1px solid white; */
/*     border-radius: 3px; */
    margin-left: 30px;
    padding: 4px 8px;
    align-items: center;
    line-height: 1em;
    font-size: 16px;
    &::before {
      
    }
    >span {
      display: block;
      position: relative;
      &:last-of-type {
        margin-left: 5px;
      }
      em, strong {
        display: block;
        text-transform: uppercase;
      }
      em {
        font-size: 12px;
        font-weight: bold;
      }
      strong {
        font-size: 14px;
      }
    }
  }
}
.intro {
  height: 720px;
  position: relative;
  box-sizing: border-box;
  padding-top: 100px;
  margin-bottom: 30px;
  .bg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 100px;
      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#360b6f+0,00439b+30,21b2d8+100 */
background: rgb(54,11,111); /* Old browsers */
background: -moz-linear-gradient(top,  rgba(54,11,111,1) 0%, rgba(0,67,155,1) 30%, rgba(33,178,216,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(54,11,111,1) 0%,rgba(0,67,155,1) 30%,rgba(33,178,216,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(54,11,111,1) 0%,rgba(0,67,155,1) 30%,rgba(33,178,216,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#360b6f', endColorstr='#21b2d8',GradientType=0 ); /* IE6-9 */


    }
    >div {
      position: absolute;
      top: 100px;
      left: 0;
      bottom: 0;
      right: 0;
      background: url("../img/logo-bg.svg") no-repeat 50% 50% / contain;
      opacity: .2;
      animation: 1s ease-out 0s 1 normal backwards running bglogo;
      @keyframes bglogo {
        from { transform: translateY(100px); opacity: 0; }
        to   { transform: translateY(0deg); opacity: .2; }
      }
    }
    >span {
      &::before {
        position: absolute;
        bottom: 40px;
        left: 0;
        width: 130%;
        height: 60px;
        background: #f5f8ff;
        content: "";
        transform-origin: 0% 100%;
        transform: skewY(-2deg);
        opacity: .5;
        animation: 0.75s ease-out 0.25s 1 normal backwards running bgel1;
        @keyframes bgel1 {
          from { transform: skewY(0deg); }
          to   { transform: skewY(-2deg); }
        }
      }
      &::after {
        position: absolute;
        bottom: 40px;
        left: 0;
        width: 130%;
        height: 60px;
        background: #f5f8ff;
        content: "";
        transform-origin: 0% 100%;
        transform: skewY(-1deg);
        animation: 1s ease-out 0s 1 normal backwards running bgel2;
        @keyframes bgel2 {
          from { transform: skewY(0deg); }
          to   { transform: skewY(-1deg); }
        }
      }
    }
  }
  .container {
    position: relative;
    display: flex;
    flex-direction: column;
    height: calc(100%);
  }
  .intro-element {
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    position: relative;
    color: white;
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    padding-top: 180px;
    padding-left: 60%;
    line-height: 40px;
    &::before {
      position: absolute;
      content: "";
      background: url("../img/intro-image.png") no-repeat 0% 0% / contain;
      top: 98px;
      bottom: 0px;
      left: 0px;
      width: 70%;
      animation: 0.5s ease-out 0.5s 1 normal backwards running introimg;
      @keyframes introimg {
        from { transform: translateY(30px); opacity: 0; }
        to   { transform: translateY(0); opacity: 1; }
      }
    }
    .l1 {
      font-size: 36px;
      font-weight: bold;
      position: relative;
      margin-bottom: 6px;
      animation: 0.33s ease-out 0.67s 1 normal backwards running introtxt;
      @keyframes introtxt {
        from { transform: translateY(35px); opacity: 0; }
        to   { transform: translateY(0); opacity: 1; }
      }
    }
    .l2 {
      font-size: 40px;
      font-weight: 300;
      position: relative;
      animation: 0.33s ease-out 0.67s 1 normal backwards running introtxt2;
      @keyframes introtxt2 {
        from { transform: translateY(50px); opacity: 0; }
        to   { transform: translateY(0); opacity: 1; }
      }
    }
  }
}
.home-feat {
  margin: 30px 0 50px;
}
.stitle {
  color: #27245f;
  text-transform: uppercase;
  margin-bottom: 24px;
  h1 {
    font-size: 60px;
    font-weight: bold;
    line-height: 64px;
    span {
      display: block;
      font-size: 36px;
      font-weight: 300;
      line-height: 40px;
    }
  }
}
.hf-list {
  display: flex;
  flex-wrap: wrap;
  >li {
    width: calc(50% - 18px);
    box-sizing: border-box;
    margin-bottom: 50px;
    padding-bottom: 1px;
    &:nth-of-type(2n) {
      margin-left: 36px;
      margin-top: 50px;
    }
  }
  h2 {
    color: #27245f;
    font-size: 36px;
    font-weight: 300;
    margin-bottom: 10px;
  }
  .hf-box {
    background: white;
    padding: 14px 22px 36px;
    border-left: 12px solid #fbb03d;
    position: relative;
    color: #333;
    line-height: 25px;
  }
  .more-btn {
    position: absolute;
    bottom: -23px;
    right: 20px;
  }
}
.more-btn {
  display: block;
  width: 240px;
  height: 46px;
  line-height: 46px;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  color: white;
  border-radius: 6px;
  transition: .3s;
  overflow: hidden;
  z-index: 1;
  &::before {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#32457b+0,32457b+50,32457b+50,4c90ba+100 */
background: rgb(50,69,123); /* Old browsers */
background: -moz-linear-gradient(left,  rgba(50,69,123,1) 0%, rgba(50,69,123,1) 50%, rgba(50,69,123,1) 50%, rgba(76,144,186,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(left,  rgba(50,69,123,1) 0%,rgba(50,69,123,1) 50%,rgba(50,69,123,1) 50%,rgba(76,144,186,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to right,  rgba(50,69,123,1) 0%,rgba(50,69,123,1) 50%,rgba(50,69,123,1) 50%,rgba(76,144,186,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#32457b', endColorstr='#4c90ba',GradientType=1 ); /* IE6-9 */

    transition: .6s;

    width: 200%;
    content: "";
    position: absolute;
    left: -100%;
    top: 0;
    height: 100%;
    z-index: -1;
  }
  &:hover {
    &::before {
      left: 0;
    }

  }
}
.home-testimonials {
  margin: 30px 0 50px;
  overflow-x: hidden;
}
.ht-list-con {
  position: relative;
  margin-top: 50px;
  .container {
    position: relative;
  }
  &::before {
    content: "";
    position: absolute;
    top: 42px;
    bottom: 62px;
    left: 0px;
    right: 0px;
    transform-origin: 50% 50%;
    transform: skewY(-1deg);
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#fbb13f+0,fdd41e+100 */
background: rgb(251,177,63); /* Old browsers */
background: -moz-linear-gradient(left,  rgba(251,177,63,1) 0%, rgba(253,212,30,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(left,  rgba(251,177,63,1) 0%,rgba(253,212,30,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to right,  rgba(251,177,63,1) 0%,rgba(253,212,30,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fbb13f', endColorstr='#fdd41e',GradientType=1 ); /* IE6-9 */
    box-shadow: 0px 0px 40px rgba(0,0,0,.1);
  }
}
.ht-list {
  display: flex;
  flex-wrap: wrap;
  >li {
    width: calc(33.33% - 20px);
    margin-bottom: 35px;
    &:nth-of-type(3n+2) {
      margin: -15px 30px 35px;
    }
  }
  blockquote {
    background: white;
    box-sizing: border-box;
    position: relative;
    padding: 32px;
    padding-bottom: 28px;
    span {
      display: block;
      margin-top: 8px;
      font-size: 14px;
      color: #4c90ba;
      font-style: italic;
    }
    div {
      color: #27245f;
      line-height: 25px;
      text-indent: 10px;
    }
  }
}
.home-advantage {
  margin: 50px 0;
}
.ha-content {
  h3 {
    font-size: 24px;
    font-weight: 300;
    color: #fbb03d;
    font-style: italic;
    text-align: center;
  }
  .ha-split {
    display: flex;
    justify-content: space-between;
    >div {
      width: calc(50% - 15px);
    }
  }
  .before {
    background: white;
    padding: 20px 0;
    ul {
      padding-left: 50px;
      >li {
        display: flex;
        align-items: center;
        margin: 20px 0;
        position: relative;
        &::after {
          content: "";
          width: 20%;
          border-top: 1px dashed #888;
          position: absolute;
          top: calc(100% + 10px);
          left: 25%;
          transform-origin: 50% 50%;
        }
        &:nth-of-type(2n) {
          padding-right: 10%;
          justify-content: flex-end;
          flex-direction: row-reverse;
          text-align: right;
          &::after {
            transform: rotate(-15deg);
          }
        }
        &:nth-of-type(2n+1) {
          padding-left: 10%;
          justify-content: flex-start;
          &::after {
            transform: rotate(15deg);
          }
        }
        &:last-of-type::after {
          display: none;
        }
        span {
          width: 50%;
          display: block;
        }
      }
      img {
        width: 64px;
        height: 64px;
        object-fit: contan;
        display: block;
        margin: 0 20px;
        border-radius: 32px;
      }
    }
  }
  .after {
    background: white;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    ul {
      width: 300px;
      height: 300px;
      background: #f8f8f8;
      margin: auto;
      position: relative;
      >li {
        display: flex;
        align-items: center;
        position: absolute;
        width: 64px;
        span {
          width: 100px;
        }
        &::after {
          content: "";
          border-left: 1px dashed #888;
          position: absolute;
          top: calc(100% + 10px);
          left: 32px;
          height: 24px;
          transform-origin: 50% -42px;
        }
        &:nth-of-type(1) {
          top: 10px;
          left: calc(50% - 32px);
          transform-origin: 32px 32px;
          transform: translateY(108px) rotate(0deg) translateY(-108px) rotate(0deg);
          &::after { 
            transform: rotate(0deg);
            animation: 0.5s ease-out 1s 1 normal backwards running afteranim1af;
          }
          animation: 0.5s ease-out 1s 1 normal backwards running afteranim1;
          @keyframes afteranim1af {
            from { opacity: 0; transform: rotate(-20deg); }
            to { opacity: 1; transform: rotate(0deg); }
          }
          @keyframes afteranim1 {
            from { opacity: 0; transform: translateY(108px) rotate(-20deg) translateY(-108px) rotate(20deg); }
            to { opacity: 1; transform: translateY(108px) rotate(0deg) translateY(-108px) rotate(0deg); }
          }
        }
        &:nth-of-type(2) {
          top: 10px;
          left: calc(50% - 32px);
          transform-origin: 32px 32px;
          transform: translateY(108px) rotate(72deg) translateY(-108px) rotate(-72deg);
          &::after {
            transform: rotate(72deg);
            animation: 0.5s ease-out 1.3s 1 normal backwards running afteranim2af;
          }
          animation: 0.5s ease-out 1.3s 1 normal backwards running afteranim2;
          @keyframes afteranim2af {
            from { opacity: 0; transform: rotate(42deg); }
            to { opacity: 1; transform: rotate(72deg); }
          }
          @keyframes afteranim2 {
            from { opacity: 0; transform: translateY(108px) rotate(42deg) translateY(-108px) rotate(-42deg); }
            to { opacity: 1; transform: translateY(108px) rotate(72deg) translateY(-108px) rotate(-72deg); }
          }
        }
        &:nth-of-type(3) {
          top: 10px;
          left: calc(50% - 32px);
          transform-origin: 32px 32px;
          transform: translateY(108px) rotate(144deg) translateY(-108px) rotate(-144deg);
          &::after {
            transform: rotate(144deg);
            animation: 0.5s ease-out 1.6s 1 normal backwards running afteranim3af;
          }
          animation: 0.5s ease-out 1.6s 1 normal backwards running afteranim3;
          @keyframes afteranim3af {
            from { opacity: 0; transform: rotate(104deg); }
            to { opacity: 1; transform: rotate(144deg); }
          }
          @keyframes afteranim3 {
            from { opacity: 0; transform: translateY(108px) rotate(104deg) translateY(-108px) rotate(-104deg); }
            to { opacity: 1; transform: translateY(108px) rotate(144deg) translateY(-108px) rotate(-144deg); }
          }
        }
        &:nth-of-type(4) {
          top: 10px;
          left: calc(50% - 32px);
          transform-origin: 32px 32px;
          transform: translateY(108px) rotate(216deg) translateY(-108px) rotate(-216deg);
          &::after { 
            transform: rotate(216deg);
            animation: 0.5s ease-out 1.9s 1 normal backwards running afteranim4af;
          }
          animation: 0.5s ease-out 1.9s 1 normal backwards running afteranim4;
          @keyframes afteranim4af {
            from { opacity: 0; transform: rotate(166deg); }
            to { opacity: 1; transform: rotate(216deg); }
          }
          @keyframes afteranim4 {
            from { opacity: 0; transform: translateY(108px) rotate(166deg) translateY(-108px) rotate(-166deg); }
            to { opacity: 1; transform: translateY(108px) rotate(216deg) translateY(-108px) rotate(-216deg); }
          }
        }
        &:nth-of-type(5) {
          top: 10px;
          left: calc(50% - 32px);
          transform-origin: 32px 32px;
          transform: translateY(108px) rotate(288deg) translateY(-108px) rotate(-288deg);
          &::after { 
            transform: rotate(288deg);
            animation: 0.5s ease-out 2.2s 1 normal backwards running afteranim5af;
          }
          animation: 0.5s ease-out 2.2s 1 normal backwards running afteranim5;
          @keyframes afteranim5af {
            from { opacity: 0; transform: rotate(228deg); }
            to { opacity: 1; transform: rotate(288deg); }
          }
          @keyframes afteranim5 {
            from { opacity: 0; transform: translateY(108px) rotate(228deg) translateY(-108px) rotate(-228deg); }
            to { opacity: 1; transform: translateY(108px) rotate(288deg) translateY(-108px) rotate(-288deg); }
          }
        }
        &:last-of-type::after {
          display: none;
        }
        span {
          width: 50%;
          display: block;
        }
        &.main {
          top: calc(50% - 32px);
          left: calc(50% - 32px);
          padding: 0;
          span {
            display: none;
          }
        }
      }
      img {
        width: 64px;
        height: 64px;
        object-fit: contan;
        display: block;
        margin: 0 20px;
        margin-left: 0;
        border-radius: 32px;
      }
    }
  }
}
